<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-08 15:41:17
 * @Message: Nothing
-->
<template>
    <div>
        <!-- header pages -->
        <div class="header-pages">
            <div class="pages-title">
                <div class="container">
                    <h2>Page Not Found</h2>
                </div>
            </div>
        </div>
        <!-- end header pages -->

        <!-- 404 -->
        <div class="page404">
            <div class="container">
                <img src="../../assets/img/404.png" />
                <h2>Page not found</h2>

                <button class="button" @click="backToHome">Go to Home</button>
            </div>
        </div>
        <!-- end 404 -->
    </div>
</template>

<script>
export default {
    name: "Page404",
    methods: {
        backToHome() {
            this.$router.push("/home");
        },
    },
};
</script>

<style scoped></style>
